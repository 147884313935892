import * as React from 'react'

type BigHeadingProps = {
  children: React.ReactNode
}

function BigHeading({ children }: BigHeadingProps) {
  return (
    <h3 className="text-xl md:text-4xl font-thin leading-relaxed text-center text-tertiary uppercase tracking-widest">
      {children}
    </h3>
  )
}

export default BigHeading;
