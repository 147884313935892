import * as React from "react"
import { PageProps } from "gatsby"
import "../styles/index.css"
import Layout from "../components/Layout/Layout"
import Nav from "../components/Nav/Nav"
import Hero from "../components/Hero/Hero"
import BigHeading from "../components/BigHeading/BigHeading"

function NotFound(_props: PageProps) {
  return (
    <Layout className="bg-black text-white">
      <Hero backgroundImage={require("../images/bg/home-hero.jpg").default}>
        <Nav />
        <BigHeading>Page does not exist</BigHeading>
        <div></div>
      </Hero>
    </Layout>
  )
}

export default NotFound
